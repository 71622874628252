import React from "react"
import { Link } from "gatsby"

import Layout from "../components/de/layout"
//import Image from "../components/image"
import SEO from "../components/de/seo"

import MainImg from "./../images/main_bg.jpg"

import shape1 from "./../images/dec/1.png"
import shape2 from "./../images/dec/2.png"
import shape3 from "./../images/dec/3.png"
import shape4 from "./../images/dec/4.png"
import shape5 from "./../images/dec/5.png"
import shape6 from "./../images/dec/6.png"
import shape7 from "./../images/dec/7.png"
import shape8 from "./../images/dec/8.png"
const Onama = () => (
  <Layout>
    <SEO
      title="MOONAMAR - ÜBER UNS UND PRINZIPIEN UNSERER ARBEIT"
      description="MOONAMAR - damit das Bewusstsein über den Segen der Natur wächst und in unseren Produkten verkörpert wird."
    />

    <section
      id="_heroPage"
      className="hero is-large _o-nama"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">ÜBER UNS</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column _headline">
                <div className="content">
                  <p>
                    <strong>
                      MOONAMAR Präparate werden mit viel Liebe , Sorgfalt und
                      Hingabe entwickelt, mit dem Anliegen, dass die Schätze der
                      Natur jedem zuganglich werden. Sie entstehen als Ergebnis
                      von 20-jähriger Erfahrung und Arbeit auf den Gebieten der
                      wissenschaftlichen und traditionellen Medizin, der
                      Mikrobiologie und der Biochemie.
                    </strong>
                  </p>
                  <p>
                    In Zusammenarbeit mit unseren zahlreichen Partnern arbeiten
                    wir daran, Naturprodukte mit den ausgeprägtesten wohltuenden
                    Wirkungen zu identifizieren und Ihnen als exklusive Auswahl
                    der MOONAMAR-Zubereitung zur Verfügung zu stellen.
                  </p>
                  <p>
                    Alle Präparate werden mit Rücksicht auf die ökologischen
                    Grundsätze ausgewählt und entwickelt, aber auch nach
                    Halal-Standards, wobei der Schwerpunkt auf die Abwesenheit
                    von Alkohol bzw. auf die Substanzen tierischen Ursprungs
                    gelegt wird. Im Fall einer Verwendung Gelatine-Kapseln
                    tierischen Ursprungs besorgen wir ausschließlich
                    halal-zertifizierte Kapseln.
                  </p>
                  <p>
                    Wir unterstützen Sie gerne mit jeglichen Informationen über
                    die MOONAMAR Produkte. Alle, die unsere Präparate gerne
                    verwenden, aber sie im lokalen Einzelhandel nicht finden
                    können, ermöglichen wir eine einfache Bestellung über unser
                    Bestellformular.
                  </p>
                  <Link
                    to="/de/bestellformular"
                    className="button is-primary is-rounded is-small btn1head"
                  >
                    ZUR BESTELLUNG
                  </Link>
                  <br />
                  <br />
                  <p>
                    Für alle Fragen stehen wir Ihnen auch telefonisch unter
                    <a href="tel:+43 681 20 446 158"> +43 681 20 446 158</a>,
                    oder per E-Mail{" "}
                    <a href="mailto:support@moonamar.co">
                      support@moonamar.co{" "}
                    </a>{" "}
                    zur Verfügung.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Onama
